<template>
  <div id="box">
    <div class="top">
      <img src="../../../../static/image/left.png" @click="back" alt="" />
      <div class="order_text">确认交易密码</div>
      <div class="over" @click="over">完成</div>
    </div>
    <!-- 密码输入框 -->
    <van-password-input
      :value="value"
      :error-info="errorInfo"
      :focused="showKeyboard"
      @focus="showKeyboard = true"
      :gutter="10"
    />
    <!-- 数字键盘 -->
    <van-number-keyboard
      :show="showKeyboard"
      @input="onInput"
      @delete="onDelete"
      @blur="showKeyboard = false"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: '',
      showKeyboard: true,
      errorInfo: '',
      id: '',
    };
  },

  mounted() {
    this.$toast('请确认交易密码');

    this.id = this.$route.query.id;
  },
  methods: {
    over() {
      if (this.id.value == this.value) {
        let params = {
          token: localStorage.getItem('token'),
          payPassword: this.value,
        };
        this.$api.anyPaypassword(params).then((res) => {
          if (res.code == 0) {
            this.$toast(res.msg);
            this.$router.go(-2);
            // this.$router.push('/Safety');
          }
        });
      } else {
        // this.$router.push('/Deal');
        this.$router.back();
        this.$toast('两次输入不一致，请重新输入');
      }
    },
    onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        let arr = document.querySelector('.over');
        arr.style.color = '#0754D3';
      } else {
        this.errorInfo = '';
      }
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },
    // 返回上一页
    back() {
      this.$router.back();
      // this.$router.push('/Deal');
      this.$toast('重新输入');
    },
  },
};
</script>
<style lang="less" scoped>
#box {
  background-color: #e8e8e8;
  height: 100vh;
}
.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  background-color: #ffffff;
  margin-bottom: 20px;
  justify-content: space-between;
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    font-size: 18px;
  }
  .over {
    margin-right: 18px;
    font-size: 15px;
    color: #cecece;
  }
}
/deep/.van-password-input {
  margin-top: 150px;
  margin-bottom: 20px;
}
</style>
